<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:navigableDetail="false"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:readOnly="readOnly"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
		></pui-datatable>
		<equipamientoqrequip-modals :modelName="modelName" :filtro="externalFilter"></equipamientoqrequip-modals>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import equipamientoqrequipActions from './EquipamientoqrequipActions';
import equipamientoqrequipModals from './EquipamientoqrequipModals.vue';

export default {
	name: 'equipamientoqrequip-grid',
	components: {
		'equipamientoqrequip-modals': equipamientoqrequipModals
	},
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'equipamientoqrequip',
			actions: equipamientoqrequipActions.gridactions,
			modelColumnDefs: {}
		};
	}
};
</script>
