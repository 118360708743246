const generaqrAction = {
	id: 'idGeneraqrAction',
	selectionType: 'general', // [single|multiple|general]
	label: 'action.equipamientoqrequip.generaqrAction',
	functionality: 'WRITE_EQUIPAMIENTOQR', // set the functionality if needed
	showInForm: true,
	checkAvailability: function(/*vue, registries*/) {
		// Validation to execute action
		return true;
	},
	runAction: function(action, model, registries) {
		const objectPk = {};
		var row = registries[0];
		if (row) {
			// Get PK for the header
			for (var index in model.columns) {
				const column = model.columns[index];
				if (column.isPk) {
					objectPk[column.name] = registries[0][column.name];
				}
			}
		} else {
			row = { contenidoqr: null };
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-equipamientoqrequipGeneraqrAction-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [generaqrAction],
	formactions: []
};
